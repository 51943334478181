import React, { FC } from 'react';

import RichText from '@components/RichText/RichText';
import { useLinkComponent } from '@link';
import { wrap } from '@sitecore/common';
import { AccordionRendering } from '@sitecore/types/manual/Accordion';
import { Accordion as SparkyAccordion, Text, Stack, Heading, TextLink, ButtonLink } from '@sparky';

const Accordion: FC<AccordionRendering> = ({ fields, params }) => {
  const Link = useLinkComponent();

  const headingLevel = params?.headingLevel ?? 'h2';

  return (
    <Stack gap="6">
      {(fields.title?.value || fields.title?.editable) && (
        <Heading size={{ initial: 'S', md: 'M' }} as={headingLevel} color="inherit">
          {wrap(fields.title)}
        </Heading>
      )}
      {(fields.content?.value || fields.content?.editable) && (
        <Text>{wrap(fields.content, <RichText html={fields.content.value} />)}</Text>
      )}
      <Stack.Item>
        <SparkyAccordion>
          {fields.items?.map(
            item =>
              (item?.fields?.question?.value || item?.fields?.question?.editable) && (
                <SparkyAccordion.Item key={item.id} id={item.id} heading={item?.fields?.question?.value}>
                  {wrap(
                    item?.fields?.question,
                    <Stack direction="column" alignY="center" alignX="start" gap="4">
                      <Text as="p">{wrap(item?.fields?.answer, <RichText html={item?.fields?.answer?.value} />)}</Text>,
                      {item?.fields?.buttonLink?.value?.href && item?.fields?.buttonLink?.value?.text && (
                        <ButtonLink
                          href={item?.fields?.buttonLink?.value?.href}
                          {...(item?.fields?.buttonLink?.value?.target && {
                            target: item?.fields?.buttonLink?.value?.target,
                          })}>
                          {item?.fields?.buttonLink?.value?.text}
                        </ButtonLink>
                      )}
                      {item?.fields?.link?.value?.href && item?.fields?.link?.value?.text && (
                        <Link
                          linkType={item?.fields?.link?.value?.linktype}
                          linkValue={item?.fields?.link?.value}
                          {...(item?.fields?.link?.value?.target && {
                            target: item?.fields?.link?.value?.target,
                          })}>
                          <TextLink emphasis="high">{item?.fields?.link?.value?.text}</TextLink>
                        </Link>
                      )}
                    </Stack>,
                  )}
                </SparkyAccordion.Item>
              ),
          )}
        </SparkyAccordion>
      </Stack.Item>
      {(fields.text?.value || fields.text?.editable) && (
        <Text>{wrap(fields.text, <RichText html={fields.text.value} />)}</Text>
      )}
      {((fields.link?.value?.href && fields.link?.value?.text) || fields.link?.editable) && (
        <Link editable={fields.link.editable} linkType={fields.link.value.linktype} linkValue={fields.link.value}>
          <TextLink emphasis="medium">{fields.link.value.text}</TextLink>
        </Link>
      )}
    </Stack>
  );
};

export default Accordion;
